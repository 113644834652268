hr {
  height: 1px;
  opacity: 1 !important;
  color: white;
  width: 74%;
  margin-left: 2rem;
}
.borderless {
  .form-control {
    background-color: transparent;
    border: none !important;
    border-bottom: 1px solid #80808047 !important;
    border-radius: 0px !important;
  }
}
#trades {
  .form-control {
    background-color: transparent;
    border: none !important;
    border-bottom: none !important;
    border-radius: 0px !important;
  }
}
.padd {
  padding: 2rem 6rem !important;
}
.text-end {
  text-align: right !important;
}
.text-start {
  text-align: left !important;
}
.lable-end {
  text-align: end;
}
@media (max-width: 990px) {
  .lable-end {
    text-align: left;
  }
  .padd {
    padding: 1rem 2rem !important;
  }
}

.close-button {
  // padding: 1px 8px;
  font-size: 28px;
  // color: white;
  // font-weight: 600;
  // border-radius: 15px;
  top: 15px;
  color: #f58120;
  // background: #F58120;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.table > thead {
  display: none !important;
}
.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.right-border {
  border-right: 1px solid #80808080;
}
.left-border {
  border-left: 1px solid #80808080;
}


.nav-tabs.three-divide {
  .nav-item {
    width: 33.33%;
    text-align: center;
    font-size: 0.9rem;
  }
}

.selection-cell input[type="checkbox"] {
  width: 1.3em !important;
  height: 1.3em !important;
  &:checked {
    background-color: #f58120 !important;
    border-color: #f58120 !important;
  }
}
.search-bar {
  display: flex;
  span {
    margin-right: 15px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 7px;
  }
  .icon {
    font-size: 20px;
    padding: 6px;
    margin-left: -32px;
  }
}
.setting {
 
  .row {
    display: flex;
    align-items: center;
  }
  label {
    font-size: 12px;
    color: #f58120;
  }
  .form-control {
    border: none !important;
    padding: 6px 0 !important;
  }
}
.radio-group {
  .form-control:read-only {
    background-color: transparent;
    opacity: 1;
  }
  .box {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 4px;
    padding: 0.47rem 0.75rem;
    border-radius: 4px;
  }
  .form-check {
    padding-left: 0;
    .form-check-input {
      float: right !important;
      margin-left: 10px;
    }
  }
}
.box {
  box-shadow: rgba(99, 99, 99, 0.6) 0px 0px 4px 1px;
  padding: 0.47rem 0.75rem;
  border-radius: 4px;
}
.chargrDiv {
  background-color: #fafafa;
  border-top: 1px solid #ebebeb;

}

.form-check {
  label {
    font-size: 12px;
    margin-top: 2px;
    color: gray !important;
  }
  .form-check-input {
    width: 1.2em !important;
    height: 1.2em !important;
    &:checked {
      background-color: #f58120 !important;
      border-color: #f58120 !important;
    }
  }
}
.trend-div {
  margin-top: -20px;
  background-color: #dcdcdc;
  padding: 1.1rem 1.6rem;
  height: 55px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}
.error-div {
  line-height: normal;
  background-color: #dd5d5d;
  color: #fff;
  i {
  
  }
}
tr td.reset-expansion-style {
  padding: 0px !important;
  height: 10px !important;
  background-color: #dcdcdc;
}

//tredes
.toast-header {
  display: block;
  align-items: center;
  padding: 0.55rem 0.75rem;
  color: white;
  position: relative;
  background-color: var(--theme-color1);
  strong {
    font-weight: 500;
  }
  .close {
    background: #bd0505 escape-svg($btn-close-bg) center / $btn-close-width auto
      no-repeat !important;
    background: #bd0505;
    border: 0;
    color: white;
    font-size: 8px;
    padding: 1px 1px;
    opacity: 1;
    width: 2.5em;
    height: 2.5em;
    border-radius: 13px;
    cursor: pointer;
    top: -6px;
    right: -6px;
  }
}
.add-trades {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #f58120;
  border: none;
  font-size: 14px !important;
  &:hover {
    background-color: #f58120;
    color: white;
  }
  i {
    top: 4px;
    font-size: 20px;
    margin-right: 10px;
    position: relative;
  }
}

.modal-header {
  border-bottom: 1px solid transparent;
}

.dashboardTab {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  .nav-item {
    width: auto;
    .nav-link {
      color: gray;
      background-color: transparent;
      &.active {
        color: #f58120;
        background-color: transparent;
      
      }
    }
  }
}
