.form-control:read-only {
  background-color: #00000014;
  opacity: 1;
}
.success-modal {
  box-shadow: gray 0px 5px 15px;
  padding: 5rem;
}
.win-cup {
  height: 7rem;
  width: 7rem;
}
@media (max-width: 990px) {
  .success-modal {
    padding: 1rem;
  }
  .win-cup {
    height: 4rem;
    width: 4rem;
  }
}
.mR-3 {
  margin-right: 3rem;
}
@media (max-width: 600px) {
  .pagetitle {
    // &.h4{
    font-size: 14px !important;
    // }
  }
  .mR-3 {
    margin-right: 5px;
  }
}
.success-img {
  height: 12rem;
  width: 12rem;
}
.preferred-partner {
  padding: 0px 4rem 0 4rem;
  color: #6b6363;
}
.position-relative {
  position: relative;
}
.partner-logo {
  font-size: 25px;
  padding: 10px 20px;
}
.center-text {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.main-header {

}
.partner-header {
  padding: 1.6rem 2.5rem;
  display: flex;
  flex-flow: row wrap;
  .partner-logo {
    font-size: 25px;
    float: left;
    width: 30%;
    text-align: left;
  }
  .partner-title {
    font-size: 24px;
    font-weight: 600;
    float: left;
    width: 70%;
    text-align: left;
    margin-top: 5px;
  }
}
.border1 {
  border-left: 4px solid #f58120;
}
.border2 {
  border-left: 4px solid gray;
}
.border-radius-none {
  border-radius: 0;
}
.partner-footer {
  padding: 0 2.5rem 2rem 2.5rem;
}
#wrapper {
  text-align: center;
  box-sizing: border-box;
  p {
    font-size: 12px;
  }
  #form {
    max-width: 100%;
    margin: 0;
    input {
      margin: 0 5px;
      display: inline !important;
      text-align: center;
      line-height: 80px;
      font-size: 50px;
      border: solid 1px #ccc;
      box-shadow: 0 0 5px #ccc inset;
      outline: none;
      width: 12%;
      transition: all 0.2s ease-in-out;
      border-radius: 3px;

      &:focus {
        border-color: #f58120;
        box-shadow: 0 0 5px #f58120 inset;
      }
      &::selection {
        background: transparent;
      }
    }
  }
}

// Make an appointment
.title {
  font-size: 16px;
  color: #6b6363;
  font-weight: 600;
}
.sub-title {
  font-size: 11px;
  color: #808080;
}
.react-calendar {
  width: 100%;
  font-family: var(--bs-font-sans-serif);
  border: 1px solid transparent;
}
.react-calendar__navigation button[disabled] {
  background-color: transparent;
  .react-calendar__navigation__label__labelText--from {
    color: #6b6363;
  }
}

.react-calendar__tile {
  padding: 3.4% 2% !important;
  border-radius: 25px;
  margin: 1.6% !important;
  max-width: 11% !important;
}
.react-calendar__tile:disabled {
  background-color: transparent;
}
.react-calendar__tile--active {
  background: #f58120 !important;
  &:enabled:hover,
  &:enabled:focus {
    background: #f58120 !important;
  }
}
.react-calendar__navigation__label {
  font-size: 15px;
  font-weight: 600;
}

.react-calendar__month-view__weekdays {
  display: none !important;
}
.form-control:disabled,
.form-control:read-only {
  background-color: transparent;
  opacity: 1;
}
#appointmentTime,
#reason {
  .form-check .form-check-input {
    // display: none;
  }
  .wrapper .option {
    background: #fff;
    height: 100%;
    width: 100%;
    // display: flex;
    // justify-content: space-evenly;
    margin: 0;
    border-radius: 5px;
    cursor: pointer;

    transition: all 0.3s ease;
    &.time {
      border: 2px solid transparent;
      text-align: center;
      padding: 5px 0px;
    }
    &.reason {
      border: 2px solid #f58120;
      align-items: left;
      padding: 10px 15px;
    }
  }

  input[type="radio"] {
    display: none;
  }
  #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2,
  #option-3:checked:checked ~ .option-3,
  #option-4:checked:checked ~ .option-4,
  #option-5:checked:checked ~ .option-5,
  #option-6:checked:checked ~ .option-6,
  #option-7:checked:checked ~ .option-7,
  #option-8:checked:checked ~ .option-8,
  #option-9:checked:checked ~ .option-9 {
    border-color: #f58120;
    background: #f58120;
  }
  .wrapper .option span {
    font-size: 13px;
    color: #808080;
  }
  #option-1:checked:checked ~ .option-1 span,
  #option-2:checked:checked ~ .option-2 span,
  #option-3:checked:checked ~ .option-3 span,
  #option-4:checked:checked ~ .option-4 span,
  #option-5:checked:checked ~ .option-5 span,
  #option-6:checked:checked ~ .option-6 span,
  #option-7:checked:checked ~ .option-7 span,
  #option-8:checked:checked ~ .option-8 span,
  #option-9:checked:checked ~ .option-9 span {
    color: #fff;
  }
  #reason-1:checked:checked ~ .reason-1,
  #reason-2:checked:checked ~ .reason-2,
  #reason-3:checked:checked ~ .reason-3,
  #reason-4:checked:checked ~ .reason-4,
  #reason-5:checked:checked ~ .reason-5,
  #reason-6:checked:checked ~ .reason-6 {
    border-color: #f58120;
    background: #f58120;
  }
  #reason-1:checked:checked ~ .reason-1 span,
  #reason-2:checked:checked ~ .reason-2 span,
  #reason-3:checked:checked ~ .reason-3 span,
  #reason-4:checked:checked ~ .reason-4 span,
  #reason-5:checked:checked ~ .reason-5 span,
  #reason-6:checked:checked ~ .reason-6 span {
    color: #fff;
  }
}
.height-100 {
  height: 100%;
}
.height-90 {
  height: 85.5%;
}
.uploadApplianceImage {
  background-color: #f58120;
  color: white;
  .header-profile-useradd {
    height: 100%;
    width: 100%;
    background-color: #f6f6f6;
    padding: 0px;
  }
  .header {
    .title {
      margin-top: 5px;
      // margin-bottom: auto;
      font-size: 16px;
      color: white;
      font-weight: 600;
    }
    .box {
      display: flex;
      color: gray;
      font-weight: 500;
      width: fit-content;
      padding: 5px 10px;
      background: white;
      box-shadow: gray 0px 0px 4px 1px;
      border-radius: 4px;
      i {
        margin-right: 5px;
        font-size: 25px;
      }
    }
  }
}

.nav-active {
  border-bottom: 4px solid #f58120;
}
.uploadApplianceImage {
  background-color: #f58120;
  color: white;
  .header-profile-useradd {
    height: 100%;
    width: 100%;
    background-color: #f6f6f6;
    padding: 0px;
  }
  .header {
    .title {
      margin-top: 5px;
      // margin-bottom: auto;
      font-size: 16px;
      color: white;
      font-weight: 600;
    }
    .box {
      display: flex;
      color: gray;
      font-weight: 500;
      width: fit-content;
      padding: 5px 10px;
      background: white;
      box-shadow: gray 0px 0px 4px 1px;
      border-radius: 4px;
      i {
        margin-right: 5px;
        font-size: 25px;
      }
    }
  }
}
//payment summary
#payment-summary {
  .form-control {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px !important;
  }
}
//appointmentSummary
#appointmentSummary {
  h3 {
    color: #f58120;
  }
  p {
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
    span {
      color: gray;
    }
  }
  .amount {
    color: #f58120;
  }
  .note {
    color: gray;
    font-size: 10px;
  }
  hr {
    height: 1px;
    opacity: 1 !important;
    color: gray;
    width: 100%;
    margin-left: 0;
  }
  .link {
    font-size: 15px;
  }
}

//dashboard
.filter-date {
  font-size: 15px;
  p {
    margin-bottom: 0px;
    margin-right: 10px;
  }
  i {
    padding: 1px 10px 0px 0px;
    font-size: 20px;
  }
  .react-datepicker__input-container input {
    border: none;
    padding: 0px;
    background-color: transparent;
    color: var(--theme-color1);
    text-decoration: underline;
  }
}
.leads {

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.11);
  border: 0;
  border-radius: 15px;
  text-align: center;
  position: relative;
  .text {
    font-size: 17px;
    font-weight: 500;
    color: #f58120;
  }
  .number {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  i {
    position: absolute;
    color: #f58120;
    font-size: 1.8rem;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  &.active {
    background-color: #f58120;
    .text {
      font-size: 17px;
      font-weight: 500;
      color: white;
    }
    h2 {
      color: white;
    }
    i {
      color: white;
    }
  }
}
.lead-status {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  font-size: 12px;
  font-weight: 500;
  padding: 12px;
  p {
    margin-bottom: 0px;
  }
  .tecnician {
    margin-top: 5px;
    font-weight: 200;
  }
  .icon {
    margin: auto;
    text-align: center;
  }
  &.active {
    background-color: #f58120;
    color: white;
    i {
      color: white;
    }
  }
  &.inactive {
    background-color: white;
    i {
      color: #f58120;
    }
  }
  &.disactive {
    background-color: #d2cfcf;
    i {
      color: gray;
    }
  }
}

//appointments
.line {
  color: #80808070;
  margin-left: 15px;
  margin: 11px 10px;
}
.profile-icon {
  height: 65px;
  width: 65px;
}
.profile-text {
  font-size: 1rem;
  font-weight: 600;
}
.cardInfo {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  padding: 10px;
  min-height: 10rem;
  position: relative;
  .title {
    font-size: 15px;
    font-weight: 500;
  }
  .text {
    font-size: 12px;
    font-weight: 400;
    color: gray;
    margin-bottom: 2px;
  }
  .view-image {
    position: absolute;
    bottom: 0;
    right: 15px;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    color: #f58120;
  }
  .status-info {
    padding: 6px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    &.active {
      background-color: #f58120;
      color: white !important;
    }
  }
}
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.view-image1 {
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  color: #f58120;
}

.img-icon {
  cursor: pointer;
  width: 2rem;
  height: 1.5rem;
  margin-left: 10px;
}
.solved {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  border-radius: 0px 0 17px 17px;
}
#appointmentStatus {
  // .form-check .form-check-input {
  //     display: none;
  // }
  .wrapper .option {
    background: #fff;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    &.status {
      border: 2px solid transparent;
      text-align: center;
      padding: 5px 0px;
    }
  }

  input[type="radio"] {
    display: none;
  }
  #option-10:checked:checked ~ .option-10,
  #option-11:checked:checked ~ .option-11,
  #option-12:checked:checked ~ .option-12 {
    border-color: #f58120;
    background: #f58120;
  }
  .wrapper .option span {
    font-size: 13px;
    color: #808080;
  }
  #option-10:checked:checked ~ .option-10 span,
  #option-11:checked:checked ~ .option-11 span,
  #option-12:checked:checked ~ .option-12 span {
    color: #fff;
  }
}
.chat {
  position: absolute;
  bottom: 15px;
  right: 55px;
  font-size: 15px;
  font-weight: 600;
  background-color: #f58120;
  color: white;
  width: 4.5rem;
  height: 4.5rem;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  p {
    margin-bottom: 0px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
}
.box {
  // margin: auto;
  text-align: center;
  // padding: 3rem;
  &.upload {
    margin: auto;
    padding: 3rem;
  }
  .text {
    color: #f58120;
    font-size: 15px;
    font-weight: 500;
  }
  .number {
    color: gray;
    font-size: 3rem;
    font-weight: 600;
  }
}
.react-datepicker__input-container {
  input {
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.react-bootstrap-table-pagination-total {
  font-weight: 600;
  font-size: 14px;
  margin-left: 1rem;
}

//dropzone
.padd-dropzon {
  padding: 1rem 3rem;
}
.dropzone {
  border: 2px dotted gray;
  border-radius: 5px;
  padding: 2rem;
}
.fileList {
  ul {
    list-style-type: none;
    padding-left: 0px;
    li {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      .fileName {
        margin-left: 1rem;
        p {
          margin-top: auto;
          margin-bottom: auto;
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 15rem;
        }
        span {
          font-size: 12px;
          color: gray;
        }
      }
      .deleteFile {
        position: absolute;
        right: 10px;
      }
    }
  }
}
.summary {
  .card.highlight {
    border: 2px solid #f58120 !important;
    // box-shadow: #F58120 0px 2px 8px 0px !important;
    position: relative;
  }
  .sendCustomer {
    position: absolute;
    bottom: -21;
    left: 38%;
    padding: 7px 40px !important;
  }
}
.bgChat {
  border: 1px solid white;
  padding: 25px;
  background: url("../../../images/sofa.jpg");
  background-repeat: no-repeat;
  background-size: auto;
}
