.appouter {
  position: relative;
  min-width: 1024px;
}
.texthide {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 49px;
  background-color: #eeeeee;
}
@media screen and (max-width: 1170px) {
  .texthide {
    width: 325px;
  }
}
@media screen and (max-width: 1000px) {
  .texthide {
    width: 325px;
  }
}
