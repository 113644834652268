// 
// authentication.scss
//

.pl-1{
    padding: 0px 1rem;
}
.font-w{
    font-weight: 600;
}



.box-shadow{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.sign-in-logo{
    font-size: 28px;
    padding: 1rem;
}
// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    left: 25px;
    font-size: 2rem
}
.need-repair{
    background-color: #F58120
}

// auth 2

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

// Preffered partner login
@media (max-width: 950px) {

}
.repair-div{
    background-color: #F58120;
    color: white;
    .plr-1{
        padding: 0px 45px;
    }
    .appointment{
        color: #74788d !important;
        background-color: #fff !important;
        border-color: #F58120;
    }
    .text-primary {
        color: white !important;
    }
    .need-repair{
        .divide{
            border: 3px solid white;
            color: white;
            font-weight: 600;
            padding-top: 10px;
            cursor: pointer;
            text-align: center;
            &:hover{
                color: #74788d !important;
                background-color: #fff !important;
                & .frozen{
                    background: url("../../../images/login/frozen-dark-icon.png") no-repeat center top;
                }
                & .leaky{
                    background: url("../../../images/login/leaky-dark-icon.png") no-repeat center top;
                }
                & .failure{
                    background: url("../../../images/login/ac-fan-dark-icon.png") no-repeat center top;
                }
                & .other{
                    background: url("../../../images/login/cooling-dark-icon.png") no-repeat center top;
                }
            }
            p{
                margin-top: -35px;
                margin-bottom: 10px;
            }
            & .frozen{
                background: url("../../../images/login/frozen-light-icon.png") no-repeat center top;
                width: 100%;
                height: 115px;
            }
            & .leaky{
                background: url("../../../images/login/leaky-light-icon.png") no-repeat center top;
                width: 100%;
                height: 115px;
            }
            & .failure{
                background: url("../../../images/login/ac-fan-light-icon.png") no-repeat center top;
                width: 100%;
                height: 115px;
            }
            & .other{
                background: url("../../../images/login/cooling-light-icon.png") no-repeat center top;
                width: 100%;
                height: 115px;
            }
        }
    }
}

.form-horizontal{
    .form-group{
    label{color:#808080}
    .form-control{
      background-color:#E2E2E2;
      border: 0;
      min-height:44px;
    }
  }
  }