@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme

@import "./bootstrap.scss";
@import "./app.scss";
@import "~video-react/styles/scss/video-react"; // or import scss

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";
:root {
  --theme-color1: #f58120;
  --chargediv-color: #ffe9d7;
  --hover-color: #f58120;
  --pagnation-color: #f58120;
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

body {
  * {
    outline: none;
  }
}
.theme-color-1 {
  background-color: var(--theme-color1) !important;
}
.theme-color-2 {
  color: var(--theme-color1) !important;
}
.theme-color-3 {
  border-color: var(--theme-color1) !important;
}
.hover-color:hover {
  background-color: var(--hover-color) !important;
  color: white !important;
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }

        &.active {
          background-color: #fafafa;
          color:var(--theme-color1)!important;
          i{
            color:var(--theme-color1)!important;
          }
       
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}
.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@import "./emailTemplate.scss";

.tabs-links {
  .nav {
    .nav-item {
      margin-right: 1.5rem;
      .nav-link {
        transition: none;
        padding: 10px 0;
        color: #767676;
        &:hover {
          color: #f58120;
        }
        &.nav-active {
          color: #000;
        }
      }
    }
  }
}
.fs-14 {
  font-size: 14px;
}
.upload-doc-btn {
  button {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.22%);
    padding: 4px 12px;
    border-radius: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    max-width: 124px;
    border: 0;
    background: #fff;
    cursor: pointer;
    .bx {
      font-size: 37px;
      color: #cdcdcd;
      margin-right: 5px;
    }
  }
}
.custom-select {
  position: relative;
  .form-group {
    .form-control {
      background-color: #e9e9e9;
      border: 0;
      height: 44px;
      color: #000;
    }
  }
  .bx {
    position: absolute;
    top: 8px;
    right: 5px;
    font-size: 29px;
    pointer-events: none;
  }
}
.progress-bar {
  background-color: #f58120 !important;
}
.progress {
  background-color: rgb(240, 233, 233) !important;
}
.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.switch-wrapper.large {
  height: 40px;
  width: 80px;
}
.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 4px;
  width: 14px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}
.card__borders {
  border: 1px solid black;
  border-radius: "5px";
}
.css-1vpurlb-InputColor {
  display: inline !important;
}
.thead-show .table > thead {
  display: contents !important;
}

.enterprise__logo {
  height: "10rem";
  overflow: "auto";
  overflow-x: hidden;
  background-color: "#f8f8fb";
}

.select-images-c h5 {
  font-size: 12px;
  margin: 0;
}
.select-images-c {
  position: relative;
  height: 133px;
  width: 133px;
  overflow: hidden auto;
  background-color: rgb(248, 248, 251);
}
.select-images-c input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
#fuplod-company-logo {
  color: #37393c !important;
}

.row.row-gutter-10 {
  margin-left: -5px;
  margin-right: -5px;
  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.f-pass {
  text-align: right;
}
.form-check .form-check-input:checked {
  background-color: var(--theme-color1) !important;
  border-color: var(--theme-color1) !important;
}
.input-group-text {
  background-color: var(--theme-color1) !important;
  border-color: var(--theme-color1) !important;
}
.error-card {
  width: 100%;
  margin: 0 auto;
  max-width: 1024px;
  min-height: 700px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.error-card img {
  max-width: 100%;
  display: inline-block;
}

.error-card p {
  font-size: 20px;
}
.no-internet__width {
  width: 100%;
}

.no_internet_p {
  margin-top: 0;
  color: #707070;
}
.no_internet_h2 {
  font-size: 35px;
  margin: 13px 0;
  font-weight: 500;
  color: #6e6e6e;
}
.no_internet_text-center {
  text-align: center;
}
.no_internet_back-btn {
  background: #f5811f;
  color: #fff;
  text-decoration: none;
  padding: 8px 25px;
  display: inline-block;
  border-radius: 10px;
}
.bottom-b-border {
  border-bottom: 1px solid #4e4e4e80;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(41, 37, 37, 0.5);
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--pagnation-color) !important;
  border-color: var(--pagnation-color) !important;
}
@function set-notification-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}
$notification-confirm: hsla(27.3, 91.4%, 54.3%, 3);

%notification {
  border-radius: 10px;
  display: block;
  font-size: 1.5em;
  font-family: sans-serif;
  padding: 1em 2em;
  margin: 1em auto;
  width: 30%;
  text-align: center;
}

.notification {
  @extend %notification;
}
.notification-confirm {
  background: $notification-confirm;
  color: set-notification-text-color($notification-confirm);
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-image: var(--body-bg);
//   font-family: Helvetica, sans-serif;
// }

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 5px;
  border-radius: 15px;
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #ffe9d7;
  border: 2px solid #f58120;
  color: #000;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 4px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 4px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: #f58120;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.send__message__btn {
  background: #6d16a7 !important;
  color: rgb(139, 26, 26) !important;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.start_call {
  display: flex;
  align-items: center;
  justify-content: center;
}


.mvh-100 {
  min-height: 100vh;
}

.row.no-gutters,
.row.no-gutters > div {
  --bs-gutter-x: 0px;
}

.box-shadow-hide {
  box-shadow: none;
}
.max-w-1024 {
  max-width: 1024px;
}
.max-w-570 {
  max-width: 570px;
}
.max-w-650 {
  max-width: 650px;
}
.mvh-100 {
  min-height: 100vh;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fsr-16 {
  font-size: 1rem;
}
.fsr-20 {
  font-size: 1.25rem;
}
.fsr-25 {
  font-size: 1.5625rem;
}
.fsr-30 {
  font-size: 2.1525rem;
}
.fsr-35 {
  font-size: 1.5625rem;
}
.fsr-50 {
  font-size: 3.125rem;
}

.black-400 {
  color: #666666;
}

.form-control.s-trade {
  border: 0;
  background: #fff !important;
  height: 44px;
  border-radius: 6px;
}

.c-select {
  position: relative;
  .bx {
    pointer-events: none;
    position: absolute;
    right: 14px;
    top: 14px;
    color: #000;
  }
}

.card-v2 {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
}
.card-v3 {
  box-shadow: rgba(99, 99, 99, 0.11) 0 0 3px 0px;
}

.form-style-v1 {
  label {
    color: #808080;
    font-size: 14px;
  }
  .form-control {
    background-color: #e2e2e2;
    border: 0;
    min-height: 44px;
    font-size: 14px;
  }
}

.label-size-14 {
  .form-group {
    label {
      font-size: 14px;
    }
  }
}

.modal-content {
  .dropzone {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
.btns-px-4 {
  .btn {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
.btns-pl-2 {
  .btn {
    margin-left: 1rem !important;
   
  }
}

.page-title {
  h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.technician_view {
  height: calc(100vh - 130px);
}
.shared_notes {
  height: calc(100% - 44px);
  .shared_chat {
    height: calc(100% - 95px);
    margin: 0;
  }
  .cardInfo {
    height: 100%;
    margin: 0;
  }
}

.screenshot-imgs {
  justify-content: right;
  display: flex;
  a {
    display: inline-block;
    margin-left: 10px;
  }
  img {
    width: 70px;
    height: 60px;
  }
}

.s-imges-edit {
  > div {
    width: 40px;
    height: 40px;
    display: inline-block;
    background: #ffffff;
    box-shadow: 0px 0.169353px 0.310481px rgba(34, 38, 44, 0.06),
      0px 2.11692px 4.23383px rgba(34, 38, 44, 0.04),
      -11.2902px 22.5804px 42.3383px rgba(34, 38, 44, 0.12);
    border-radius: 11.2902px;
    color: #f58120;
    font-size: 22px;
    line-height: 40px;
    margin: 0 6px;
  }
}

.confirmpopup_center {
  textarea {
    border: 1px solid #c9c9c9;
    border-radius: 10px;
    font-size: 14px;
    padding: 1rem;
    resize: none;
  }
}

.dashboardTab.nav-tabs {
  background-color: transparent;
  border: 0;

  .nav-item {
    text-align: center;
    font-size: 1rem;

    .nav-link {
      padding: 1rem 3rem;
      text-decoration: none;
      color: #495057;
      background-color: white;
      margin-right: 1rem;
      border: none;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      &.active {
        color: white;
        background-color: var(--theme-color1);
      }
      @media (max-width: 991.98px) {
        padding: 1rem 1.5rem; 
        font-size:14px;
        margin: 5px;
        border-radius: 10px;
      }
    }
  }
}

.table-style-1 {
  .react-bootstrap-table {
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  .table {
    border: 0;
    margin: 0;
    > :not(:first-child) {
      border-top: 0;
    }

    tr {
      border-bottom: 1px solid #efefef;

      &:last-child {
        border-bottom: 0;
      }

      &:nth-child(even) {
        background-color: #f7f7f7;
      }
      td {
        padding: 1rem;
        vertical-align: middle;
        border: 0;
        font-weight: 400 !important;
        font-size: 14px;

        i {
          &.bxs-show {
            font-size: 1.5rem;
            color: var(--bs-warning);
          }
          &.bxs-check-circle {
            font-size: 1.7rem;
            color: green;
          }
          &.bxs-x-circle {
            font-size: 1.7rem;
            color: #bd0505;
          }
        }
      }
      &:first-child {
        border-radius: 10px;
      }

      th{
          font-weight: 500;
        border-bottom-color: #e1e1e1;
        font-size: 14px;
      }

    }
  }
}
.table-style-2 {
  .react-bootstrap-table {
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  .table {
    border: 0;
    margin: 0;
    > :not(:first-child) {
      border-top: 0;
    }

    tr {
      border-bottom: 1px solid #efefef;

      &:last-child {
        border-bottom: 0;
      }

      &:nth-child(even) {
        background-color: #f7f7f7;
      }
      td {
        padding: 1rem;
        vertical-align: middle;
        border: 0;
        font-weight: 400 !important;
        font-size: 14px;

        i {
          &.bxs-show {
            font-size: 1.5rem;
            color: var(--bs-warning);
          }
          &.bxs-check-circle {
            font-size: 1.7rem;
            color: green;
          }
          &.bxs-x-circle {
            font-size: 1.7rem;
            color: #bd0505;
          }
        }
      }
      &:first-child {
        border-radius: 10px;
      }

      th{
          font-weight: 500;
        border-bottom-color: #e1e1e1;
        font-size: 15px;
        padding:1rem;
      }

    }
  }
  .table-action-btns{
    .bx{
      font-size:20px;
      padding: 7px 7px;
      cursor: pointer;
    }
  }


}

.form-control.search-style-1 {
  border-color: #979797;
  font-weight: 400;
  font-size: 14px;
  min-height: 40px;
}

.react-bootstrap-table-pagination {
  .react-bootstrap-table-pagination-total {
    margin-left: 1rem;
    font-weight: 400;
    font-size: 13px;
  }
  .btn-group {
    margin: 5px 0;
    .btn {
      font-size: 14px;
      padding: 7px 16px;
      border: 1px solid #dbdbdb;
      &.btn-warning{
        background-color: var(--theme-color1);
      }
    }
  }

  .react-bootstrap-table-page-btns-ul {
    margin: 5px 0;
    .page-link {
      padding: 7px 10px;
    }
  }
}

.appointment-scroll {
  max-height: 100vh;
  min-height:100px;
  overflow-y: auto;
  padding: 6px;
}

.c-scroll::-webkit-scrollbar {
  width: 8px;
}
.c-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 40px;
}
.c-scroll::-webkit-scrollbar-thumb {
  background: var(--bs-warning);
  border-radius: 40px;
}
.c-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 1600px) {
  $sidebar-width: 250px;

  .vertical-menu {
    width: $sidebar-width;
  }
  .main-content {
    margin-left: $sidebar-width;
  }
  #page-topbar {
    padding-left: $sidebar-width;
  }
}
.mchat {
  margin-bottom: 12px;
  display: flex;
  .mchat-mess {
    max-width: 50%;
    padding: 0.5rem 1rem;
    box-shadow: 6px 15px 40px rgba(62, 78, 97, 0.08);
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 12px;
  }
  &.mc-left {
    .mchat-mess {
      background-color: #fff;
      border-color: rgba(27, 45, 73, 0.05);
    }
  }
  &.mc-right {
    justify-content: end;
    .mchat-mess {
      border-color: #f58120;
      background-color: rgba(245, 129, 32, 0.04);
    }
  }
}
.mchat-box {
  max-height: 35vh;
  overflow-y: auto;
}
.mnote-box {
  max-height: 35vh;
  overflow-y: auto;
  .mnote {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .mnote-mess {
    padding: 0.5rem 1rem;
    display: inline-block;
    border-radius: 5px;
    background-color: #fffdf0;
    border: 1px solid #ede9c4;
    font-size: 13px;
  }
}

.issue-action-btn {
  display: flex;
  .btn {
    width: 100%;
    margin: 2px 10px;
    input {
      visibility: hidden;
    }
    span {
      margin-left: -14px;
    }
  }
}

#sidebar-menu {
  ul {
    li {
     .logout-link {
        background-color: transparent !important;
        color: #fff !important;
        text-align: center;
        width: 100%;
    display: block;
    padding:1rem;
      }
    }
  }
}

.cs-style-v1{
  .form-control{background: #fff!important;
  border: 1px solid #cfcfcf;
  font-size: 14px;
  min-height: 44px;
  border-radius: 10px;
}
.bx{ font-size:25px;    right: 10px;
  top: 10px;}
}
.border-none{
  border:0!important;
}

.search-bar {
  display: flex;
  align-items:center;
  position: relative;
  max-width: 350px;
  span {
  
    font-size: 1rem;
    font-weight: 500;
    margin-right:1rem;

  }
  .icon {
    font-size: 22px;
    padding: 6px;
    position: absolute;
    right: 5px;
    top: 3px;
    pointer-events: none;
   
  }
  .form-control{
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border-color: #e7e7e7;
    font-size: 14px;
    min-height: 44px;
    padding-right: 40px;
  }
}

.toast{
  &.tradesToast{

    width:100%;
    border:1px solid var(--theme-color1);
    border-radius:20px;
    padding:1.3rem;
    .toast-header{
      background-color:transparent;
      border:0;
      padding: 0 0 1.2rem 0;
      h3{color:#000;
      font-size: 1.25rem;
      margin:0;
      text-align:left;
    } 
    .close-toast{
      position: absolute;
      right: -10px;
      top: -10px;
      background: #EDEDED;
      border: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 26px;
      font-weight: 400;
    }
    }

    .toast-body{
      padding:0;
      label{margin-bottom:6px; color:#666666}
      .toast-input{
        .form-control{
          font-size: 1.25rem;
          border:0;
          border-bottom:2px solid #D7D7D7;
          border-radius:0;
          padding:5px 0;
        }
      }
    }

    h4{ 
      text-align: center;
      font-size: 1rem;
      margin-top: 1.4rem;
      margin-bottom: 1.4rem;
    }

  }
}

.appo-reasons{
  position: relative;
  .form-control{
    height: 44px;
background: #EFEFEF;
border:0;
border-radius: 10px;
font-size:14px;
  }
  .bx{
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0.5;
    font-size: 12px;
    pointer-events:none;

  }
}

.align-middle{ vertical-align:middle}

.billing-block{
  overflow: hidden;
  label{ margin-bottom:5px;}
  font-weight:500;
  p{ font-weight:400; opacity:0.5;}
  @media (min-width: 768px){
  .card-body{
    > .row{
      
        > .col{
           &:first-child{
             border-right:1px solid #cfcfcf;
           }
         }
    }
  }
}

}

.leads-count{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.11);
  border: 0;
  border-radius: 15px;
  text-align: center;
  position: relative;
  p{ margin-bottom:0;}
}

.enterprises-tabs{
  .nav-link{ font-size: 14px; padding: 10px 15px!important;}
}

.react-datepicker{
  font-size:16px;
}

.partner-logo{
  img{ width:100%; max-height:100px;}
  @media (max-width: 768px){
    img{ width:100%; max-height:70px;}
  }
}

@media (min-width: 992px) {

  .left-md-border {
    border-left: 1px solid #e7e7e7;    
  } 
  
}

.addCardModal .close{
 position:absolute;
 background-size: 16px;
}

.preloadericon{  
  .sas{
  fill:rgb(25, 231, 114);
}

}

.reloader{
  svg{
    fill: var(--theme-color1);
  }
}


.loader11 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 40px 0 var(--theme-color1);
  position: relative;
  animation: loader11 0.8s ease-in-out alternate infinite;
  animation-delay: 0.32s;
  top: 50%;
  margin: -50px auto 0 auto; }
  .loader11:after, .loader11:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 40px 0 var(--theme-color1);
    animation: loader11 0.8s ease-in-out alternate infinite; }
  .loader11:before {
    left: -30px;
    animation-delay: 0.48s; }
  .loader11:after {
    right: -30px;
    animation-delay: 0.16s; }

@keyframes loader11 {
  0% {
    box-shadow: 0 40px 0 var(--theme-color1); }
  100% {
    box-shadow: 0 20px 0 var(--theme-color1); } }
