.align-center {
	align-items: center;
}

.margin-b-05 {
	margin-bottom: 0.5rem;
}

.flex_box {
	display: flex;
	align-items: center;
}

.pointer {
	cursor: pointer;
}

#invisible {
	visibility: hidden;
}

   .ctc-update{}
    .ctc-preview{  text-align: center;
		padding: 12px 10px;
		border-radius: 0 0 17px 17px;
		font-size: 15px;}
		.ct-colorbox { text-align:center; line-height:normal;}
		.ct-colorbox .css-1yn2e29-InputColor{    width: 64px;
			height: 64px;
			border: 1px solid rgba(0, 0, 0, 0.168);
			border-radius: 8px;}
			.ct-colorbox p{ margin:0;    font-size: 14px;}
			.ct-colorbox .ct-value{
				font-size: 13px;
				opacity: 0.6;
			}
			svg.sas {
				fill: red;
			  }